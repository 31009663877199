import React from 'react';

// styles
import '../styles/index.css';
import '/node_modules/primeflex/primeflex.css';

// components
import MainLayout from '../hoc/MainLayout';
import { loadCrispChat } from '../common/utils';
import Seo from '../components/shared/Seo';

const TermsOfServicesPage = () => {
    loadCrispChat();

    return (
        <MainLayout>
            <Seo title='Lofi.co - Craft your focus environment' keywords={['music', 'focus', 'relax', 'work']} lang='en' />
            <div className='flex justify-content-center'>
                <div style={{ maxWidth: '80vw' }}>
                    <h1>Terms and Conditions</h1>
                    <p>Welcome to our website.&nbsp;</p><p>&nbsp;</p><p><span>If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Lofi.co relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</span>
                    </p><p><span>The term ‘Lofi.co’ or ‘us’ or ‘we’ refers to the owner of the website&nbsp; </span><a
                        href="http://www.lofi.co/"><span className="accent">http://www.lofi.co/</span></a><span>. The term ‘you’ refers to the user or viewer of our website. Your use of this website constitutes a contract with us.&nbsp;</span>
                    </p><p>&nbsp;</p><p className="l bold">Terms of Service</p><p><span>We offer a variety of products on our platform customized to meet your personal preference such as choosing a lofi background music loop based on your mood, you may customize it with sounds by interacting with original artworks and enjoy our productivity suite of tools.</span>
                    </p><p><span>The music played on </span><a href="http://lofi.co" className="accent"><span>lofi.co</span></a><span> is owned by </span><a
                        href="http://lofi.co" className="accent"><span>lofi.co</span></a><span> unless stated otherwise. Any use is strictly prohibited.&nbsp;</span>
                    </p><p><span>The illustrations on </span><a href="http://lofi.co"
                        className="accent"><span>lofi.co</span></a><span> are originally made for lofi.co. We own exclusive usage &amp; commercial rights. You are not allowed to use any of the art in any way. Premium plan users may be allowed to download selected illustrations to be used as wallpapers.&nbsp;</span>
                    </p><p>&nbsp;</p><p className="l bold">USER REPRESENTATIONS</p><p><span>By using the Site, you represent and warrant that:&nbsp;</span>
                    </p><p>
                        <span>(1) all registration information you submit will be true, accurate, current, and complete;</span>
                    </p><p><span>&nbsp;(2) you will maintain the accuracy of such information and promptly update such registration information as necessary;</span>
                    </p><p><span>(3) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;&nbsp;</span>
                    </p><p><span>(4) you will not use the Site for any illegal or unauthorized purpose;&nbsp;</span></p><p>
                        <span>(5) your use of the Site will not violate any applicable law or regulation.</span></p>
                    <p>&nbsp;</p><p>USER REGISTRATION</p><p><span>You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</span>
                    </p><p>&nbsp;</p><p>MODIFICATION OF THE SERVICE</p><p><span>We reserve the right, at any time, to modify, suspend, or discontinue the Service or any part thereof with or without notice.</span>
                    </p><p>&nbsp;</p><p>THIRD-PARTY WEBSITES&nbsp;</p><p>The Site may contain links to
                    other websites (&apos;Third-Party Websites&apos;) as well as articles, photographs, text, graphics, pictures,
                    designs, music, sound, video, information, applications, software, and other content or items
                    belonging to or originating from third parties (&apos;Third-Party Content&apos;). We are not responsible for
                    any Third-Party Websites accessed through the Site or any Third-Party Content posted on, available
                    through, or installed from the Site.</p><p>&nbsp;</p><p>General Limitation of
                    Liability</p><p><span>Lofi.co provides the Site on an “as is” basis and makes no representations whatsoever about any other web site which you may access through the Site or which may link to this Site. When you access a site outside the Site, please understand that it is independent from the Site and that Lofi.co has no control over the content on that web site. In addition, a link to the Site does not mean that Lofi.co endorses or accepts any responsibility for the content, or the use, of such a web site.</span>
                    </p><p>&nbsp;</p><p>Indemnification</p><p><span>You agree to indemnify, defend and hold harmless the Site, its officers, directors, employees, agents, licensors, suppliers and any third-party information providers to the Service from and against all losses, expenses, damages and costs, including legal costs: -&nbsp;</span>
                    </p>
                    <ol>
                        <li><span>resulting from any violation of these terms and conditions (including negligent or wrongful conduct) by you or any other person accessing the Site and its services;</span>
                        </li>
                        <li><span>howsoever arising as, a result of you downloading files from the Site or that we include links to; and,&nbsp;</span>
                        </li>
                        <li><span>howsoever arising as, a result of any action you take as either a direct or indirect result of information, opinions or other materials on the Site, or generated from the Site and its services.</span>
                        </li>
                    </ol>
                    <p>&nbsp;</p><p>Governing law and disputes</p><p><span>This Agreement shall be governed by and constructed exclusively in accordance with the laws of Italy. Any legal action or proceeding between Lofi.co and you&nbsp; concerning this Agreement or the parties’ obligations hereunder shall be brought exclusively in a court of competent jurisdiction sitting in Italy.&nbsp;</span>
                    </p><p>&nbsp;</p><p>Refund Policy</p><p><span>We offer a 7 day money-back guarantee on our premium plans. Please note that the refund is not automatically issued once you cancel your membership, but you will need to send us an e-mail using the ‘Contact Us’ form, with the same details used during registration. The refund will be issued as soon as we receive your message and you will see it in your balance within 7-10 business days.</span>
                    </p><p>&nbsp;</p><p>CONTACT INFORMATION</p><p><span>For any assistance or clarification kindly reach out to us via:</span>
                    </p><p><span>Email: hello@lofi.co</span></p>
                </div>
            </div>
        </MainLayout>
    );
};

export default TermsOfServicesPage;